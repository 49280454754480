import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { useQuery } from "@tanstack/react-query";
import { NotificationApi } from "states/api/notification";
import moment from "moment";

const Notice = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  const { data, isLoading, refetch } = useQuery(
    ["notification", "list"],
    () =>
      NotificationApi.getList({
        params: {
          page: 1,
          limit: 30,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <ol className="activity-feed">
      {data?.data?.map((item) => (
        <li className="feed-item feed-item-success" key={item?.id}>
          <time className="date" dateTime="9-25">
            {moment(item?.created_at).format("DD/MM/YYYY HH:mm")}
          </time>
          <span className="text" style={{ color: "white" }}>
            {item?.content}
          </span>
        </li>
      ))}
    </ol>
  );
});

export default Notice;
