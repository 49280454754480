import React, { useState } from "react";
import { useStores } from "_common/hooks";
import PageLoading from "_common/component/PageLoading";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";
import HeaderTop from "../Header/HeaderTop";
import Sidebar from "../Sidebar/Sidebar";
import MobileFooter from "../Footer/MobileFooter";

const UnAuthBasicLayout = observer(() => {
  const { authStore } = useStores();

  if (!authStore.logo) return <PageLoading />;

  return (
    <div className="mobile-layout">
      <HeaderTop />
      <Sidebar />
      <div className="main-panel">
        <div className="content">
          <Outlet />
        </div>
        <MobileFooter />
      </div>
    </div>
  );
});

export default UnAuthBasicLayout;
