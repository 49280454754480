import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";
import { handleCopyLink } from "_common/utils/helper";
import { useQuery } from "@tanstack/react-query";
import { PaymentApi } from "states/api/payment";
import { UserRefApi } from "states/api/userRef";
import { formatNumber } from "_common/utils/formatValue";
import moment from "moment";

const Information = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  const [params, setParams] = useState();

  return (
    <div className="page-inner">
      <div className="page-header">
        <h4 className="page-title">Giới Thiệu</h4>
        <ul className="breadcrumbs">
          <li className="nav-home">
            <Link to="/">
              <i className="fa fa-home" />
            </Link>
          </li>
          <li className="separator">
            <i className="fas fa-chevron-right" />
          </li>
          <li className="nav-item">
            <Link to="/cong-ty">Giới Thiệu</Link>
          </li>
        </ul>
      </div>

      <div className="card card-new">
        <div className="card-body">
          <h3> Lãnh đạo CapitaLand Việt Nam được vinh danh</h3>
          <p>
            Ông Chen Lian Pang, Tổng giám đốc CapitaLand Việt Nam vừa nhận giải
            “Nhân vật bất động sản của năm“ tại Vietnam Property Awards 2016.
          </p>
          <p>
            Ngoài ra, một số dự án cao cấp của tập đoàn này cũng được đề cử và
            vinh danh tại giải thưởng “Dự án nhà ở cao cấp xuất sắc tại TP HCM
            và Hà Nội“ như Kris Vue, The Krista, Seasons Avenue...
          </p>
          <img
            src="https://i1-kinhdoanh.vnecdn.net/2016/06/15/14-6-20163-935441874-3101-1465956195.png?w=680&h=0&q=100&dpr=1&fit=crop&s=Pe1yt1jx7uugwBIc2wTOSg"
            alt=""
          />
          <p>
            “Nhân vật bất động sản của năm“ là hạng mục duy nhất không thông qua
            xét duyệt giải của Hội đồng giám khảo mà được trực tiếp vinh danh
            cho cá nhân xuất sắc, dựa trên những thành quả đạt được tại thị
            trường bất động sản trong những năm qua.
          </p>
          <img
            src="https://i1-kinhdoanh.vnecdn.net/2016/06/15/14-6-201619-741533951-6203-1465956196.png?w=680&h=0&q=100&dpr=1&fit=crop&s=HyvDkjtJYEk-1tR5lKZ-Cg"
            alt=""
          />
          <p>
            “Cách đây khoảng 20 năm, CapitaLand xác định Việt Nam là một thị
            trường tiềm năng, có cơ cấu dân số trẻ, trí thức và lao động tích
            cực, cùng với một nền kinh tế tăng trưởng nhanh và giới trung lưu
            ngày một tăng. Trải qua nhiều thách thức, nền kinh tế Việt Nam đang
            ngày một cải thiện và chúng tôi chứng kiến dấu hiệu phục hồi của thị
            trường này”, ông Chen nói.
          </p>
          <p>
            Về các dự án của CapitaLand Việt Nam nhận giải lần này, ông Chen
            Lian Pang cho biết, giải thưởng “Dự án nhà ở cao cấp xuất sắc tại TP
            HCM và Hà Nội” tại Vietnam Property Awards 2016 như sự công nhận của
            thị trường về nỗ lực của công ty - chủ đầu tư có hơn 20 năm kinh
            nghiệm ở Việt Nam.
          </p>
          <img
            src="https://i1-kinhdoanh.vnecdn.net/2016/06/15/14-6-201641-5962-1465956197.png?w=680&h=0&q=100&dpr=1&fit=crop&s=2kGyHy7T8sXHJDmlJ7VYgA"
            alt=""
          />
          <p>
            Thời gian tới, CapitaLand Việt Nam cam kết cung cấp cho người mua
            nhà những dự án đúng tiến độ, đồng thời sẽ đóng góp tâm sức vào công
            tác xã hội tại Việt Nam.
          </p>
          <p>
            The Vietnam Property Awards thuộc hệ thống giải thưởng bất động sản
            châu Á - Asia Property Awards nhằm ghi nhận những công trình, dự án
            bất động sản có chất lượng tại nhiều quốc gia khu vực châu Á.
          </p>
          <p>
            CapitaLand Việt Nam là nhà đầu tư bất động sản lâu năm tại Việt Nam.
            Hiện tập đoàn có mặt tại 6 thành phố lớn là TP HCM, Hà Nội, Hải
            Phòng, Đà Nẵng, Bình Dương và Nha Trang, đầu tư trong lĩnh vực căn
            hộ nhà ở và căn hộ dịch vụ. Đến nay, CapitaLand đã cung cấp cho thị
            trường nhà ở TP HCM và Hà Nội khoảng 7. 850 căn hộ chất lượng tại 8
            dự án nhà ở. Ascott Limited, công ty con do CapitaLand sở hữu 100%
            vốn, có danh mục đầu tư gồm hơn 3.000 căn hộ dịch vụ thuộc 17 toà
            nhà Somerset tại 6 thành phố lớn kể trên. Ascott là chủ sở hữu kiêm
            nhà điều hành quốc tế về căn hộ dịch vụ lớn tại Việt Nam.
          </p>
        </div>
      </div>
    </div>
  );
});

export default Information;
