import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";
import { formatNumber } from "_common/utils/formatValue";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PaymentApi } from "states/api/payment";
import { NotificationManager } from "react-notifications";
import History from "./History";

const Withdraw = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { general, user, bank },
  } = useStores();

  const [valueDeposit, setValuePosit] = useState("");
  const [wallet_password, setWalletPassword] = useState("");

  const minAmount = useMemo(
    () => (general?.min_withdraw ? Number(general?.min_withdraw) : 1),
    [general]
  );

  const maxAmount = useMemo(
    () =>
      user?.max_withdraw ? Number(user?.max_withdraw) : 10000000000000000000,
    [general]
  );

  const { mutate, isLoading } = useMutation(
    (variables) =>
      PaymentApi.withdraw({
        params: {
          ...variables,
          amount: valueDeposit,
          bank_id: bank?.id,
          token_id: 3,
          wallet_password,
          type: "withdraw",
        },
      }),
    {
      onSuccess: (res) => {
        setValuePosit("");
        setWalletPassword("");
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(res?.data?.msg || "Thao tác thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleSubmit = () => {
    if (!bank?.id)
      return NotificationManager.error("Vui lòng nhập thông tin ngân hàng");
    if (!valueDeposit || valueDeposit <= 0)
      return NotificationManager.error("Vui lòng nhập số tiền");
    mutate();

    return null;
  };

  const handleChangeValuePosit = (e) => {
    setValuePosit(e?.target?.value);
  };

  const handleChangePass = (e) => {
    setWalletPassword(e?.target?.value);
  };

  return (
    <div className="page-inner">
      <div className="page-header">
        <h4 className="page-title">Rút Tiền Tài Khoản</h4>
        <ul className="breadcrumbs">
          <li className="nav-home">
            <Link to="/">
              <i className="fa fa-home" />
            </Link>
          </li>
          <li className="separator">
            <i className="fas fa-chevron-right" />
          </li>
          <li className="nav-item">
            <Link to="/rut-tien">Rút Tiền Tài Khoản</Link>
          </li>
        </ul>
      </div>
      <div className="row">
        <div className="col-md-7">
          <form method="POST" action="">
            <div className="card">
              <div className="card-header">
                <div className="card-title">Rút Tiền Tài Khoản</div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    {!bank?.id && (
                      <div className="form-group">
                        <button style={{ color: "yellow" }} type="button">
                          <Link to="/them-ngan-hang">
                            Thêm Tài Khoản Ngân Hàng
                          </Link>
                        </button>
                      </div>
                    )}

                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Chọn ngân hàng"
                        value={`${bank?.bank_owner} (${bank?.bank_number_display})`}
                        disabled
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="number"
                        placeholder={`Số tiền rút ${formatNumber(
                          minAmount
                        )} ~ ${formatNumber(maxAmount)}`}
                        value={valueDeposit}
                        onChange={handleChangeValuePosit}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nhập mật khẩu giao dịch"
                        value={wallet_password}
                        onChange={handleChangePass}
                      />
                    </div>
                    <div className="form-group">
                      <span style={{ color: "white" }}>
                        Số dư hiện tại:{" "}
                        <b style={{ color: "orange" }}>
                          {formatNumber(user?.balance)} VNĐ
                        </b>
                      </span>
                    </div>
                    <div className="form-group">
                      <span style={{ color: "white" }}>
                        Số tiền rút thực tế:{" "}
                        <b style={{ color: "orange" }}>
                          {formatNumber(valueDeposit)} VNĐ
                        </b>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-action">
                <button
                  type="button"
                  onClick={() => handleSubmit()}
                  className="btn btn-secondary"
                >
                  Xác Nhận Rút Tiền
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-5">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Hướng Dẫn Rút Tiền</div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div
                    className="form-group"
                    style={{ color: "rgba(169, 175, 187, 0.82)" }}
                  >
                    <p>
                      Nhà đầu tư vui lòng chọn&nbsp;
                      <span style={{ fontWeight: "bolder" }}>
                        Thêm Tài Khoản Ngân Hàng&nbsp;
                      </span>
                      nếu chưa có tài khoản ngân hàng trên hệ thống.
                    </p>
                    <br />
                    <p>
                      Hệ thống xét duyệt xử lý lệnh rút tiền cho quý khách trong
                      vòng 24 giờ
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <History />
        </div>
      </div>
    </div>
  );
});

export default Withdraw;
