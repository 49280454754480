import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { useQuery } from "@tanstack/react-query";
import { UserFundApi } from "states/api/userFund";
import { formatNumber } from "_common/utils/formatValue";
import moment from "moment";
import { fundUserStatusText } from "_common/constants/common";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { UserRefApi } from "states/api/userRef";

const MyTeam = observer(() => {
  const {
    authStore: { general, vips },
  } = useStores();

  const [params, setParams] = useState({});
  const [activeTab, setActiveTab] = useState(vips?.[0]?.code);

  const { data: dataState, refetch } = useQuery(
    ["user-vip", "list", activeTab, params],
    () =>
      UserRefApi.getList({
        params: {
          page: 1,
          limit: 30,
          ref_level: activeTab,
          ...params,
        },
      }),
    {
      staleTime: 300000,
      enabled: !!activeTab,
    }
  );

  useEffect(() => {
    if (!activeTab) return;
    refetch();
  }, [activeTab]);

  useEffect(() => {
    if (!vips) return;
    setActiveTab(vips?.[0]?.code);
  }, [vips]);

  return (
    <div className="page-inner">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <div className="tabs">
                {vips?.map((item) => (
                  <div
                    className={classNames("tab", {
                      active: activeTab === item?.code,
                    })}
                    key={item?.id}
                    onClick={() => setActiveTab(item?.code)}
                  >
                    {item?.code}
                  </div>
                ))}
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover table-striped">
                  <thead>
                    <tr>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        ID
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        Tài khoản
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        SL thành viên
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        Tổng nạp
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        Tổng rút
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        Tổng đầu tư
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        Tổng lợi nhuận
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataState?.data?.map((item) => (
                      <tr key={item?.id}>
                        <td>{item?.id}</td>
                        <td>{item?.username}</td>
                        <td>{formatNumber(item?.total_ref)}</td>
                        <td>{formatNumber(item?.bill_deposit)}</td>
                        <td>{formatNumber(item?.bill_withdraw)}</td>
                        <td>{formatNumber(item?.bill_fund)}</td>
                        <td>{formatNumber(item?.bill_commission)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MyTeam;
