import React from "react";
import { Routes, Route } from "react-router-dom";

import CheckAuth from "routers/CheckAuth";
import { MiddlewareRouter } from "routers/MiddlewareRouter";

import BasicLayout from "_common/component/Layout/BasicLayout";
import PageNotfound from "_common/component/PageNotfound";

// auth
import HomePage from "pages/Home";
import Profile from "pages/Profile";
import Login from "pages/Login";
import Present from "pages/Present/Present";
import Recharge from "pages/Recharge/Recharge";
import Withdraw from "pages/Withdraw/Withdraw";
import Invest from "pages/Invest/Invest";
import MyInvestment from "pages/MyInvestment/MyInvestment";
import LoginPage from "pages/Auth/Login";
import RegisterPage from "pages/Auth/Register";
import Bank from "pages/Bank";
import UnAuthBasicLayout from "_common/component/Layout/BasicLayout/UnAuthBasicLayout";
import Information from "pages/Information";
import MyTeam from "pages/MyTeam";

export function Routers() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <MiddlewareRouter>
            <BasicLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<HomePage />} />
        <Route path="dang-nhap" element={<Login />} />
        <Route path="nhom-cua-toi" element={<MyTeam />} />
        <Route path="home" element={<HomePage />} />
        <Route path="profile" element={<Profile />} />
        <Route path="gioi-thieu" element={<Present />} />
        <Route path="nap-tien" element={<Recharge />} />
        <Route path="rut-tien" element={<Withdraw />} />
        <Route path="them-ngan-hang" element={<Bank />} />
        <Route path="dau-tu" element={<Invest />} />
        <Route path="cong-ty" element={<Information />} />
        <Route path="my-investment" element={<MyInvestment />} />
      </Route>
      <Route
        path="auth"
        element={
          <CheckAuth>
            <UnAuthBasicLayout />
          </CheckAuth>
        }
      >
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
      </Route>
      <Route path="*" element={<PageNotfound />} />
    </Routes>
  );
}
