import { endpoint } from "_common/constants/common";
import api from "states/drivers";

const UserFundEndpoint = `${endpoint}/user-fund`;

export const UserFundApi = {
  getList: async ({ params }) =>
    await api
      .get(`${UserFundEndpoint}/list`, params)
      .then((result) => result.data?.data),
  getDetail: async ({ id }) =>
    await api.get(`${UserFundEndpoint}/detail/${id}`).then((result) => result.data),
  receive: async ({  params }) =>
    await api
      .post(`${UserFundEndpoint}/receive`, params)
      .then((result) => result.data),
};
