import React from "react";
import { Outlet } from "react-router-dom";
import { useStores } from "_common/hooks";
import MobileFooter from "../Footer/MobileFooter";

function MainPanel(props) {
  const {
    authStore: { general, authenticated },
  } = useStores();

  return (
    <div className="main-panel">
      <div className="content">
        <Outlet />
      </div>
      <MobileFooter />
    </div>
  );
}

export default MainPanel;
