import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import moment from "moment";
import { formatNumber } from "_common/utils/formatValue";
import FormBank from "./FormBank";

const Bank = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="page-inner">
      <div className="page-header">
        <h4 className="page-title">Thông Tin Ngân Hàng</h4>
        <ul className="breadcrumbs">
          <li className="nav-home">
            <a href="/home">
              <i className="fa fa-home" />
            </a>
          </li>
          <li className="separator">
            <i className="fas fa-chevron-right" />
          </li>
          <li className="nav-item">
            <a href="/profile">Thông tin ngân hàng</a>
          </li>
        </ul>
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <div className="card-title">Ngân hàng</div>
          </div>
          <FormBank />
        </div>
      </div>
    </div>
  );
});

export default Bank;
