import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";
import { handleCopyLink } from "_common/utils/helper";
import { useQuery } from "@tanstack/react-query";
import { PaymentApi } from "states/api/payment";
import { UserRefApi } from "states/api/userRef";
import { formatNumber } from "_common/utils/formatValue";
import moment from "moment";

const Present = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  const [params, setParams] = useState();

  const { data, isLoading, refetch } = useQuery(
    ["userRef", "list", params],
    () =>
      UserRefApi.getList({
        params: {
          page: 1,
          limit: 30,
          ref_level: "F1",
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="page-inner">
      <div className="page-header">
        <h4 className="page-title">Giới Thiệu Đầu Tư</h4>
        <ul className="breadcrumbs">
          <li className="nav-home">
            <Link to="/">
              <i className="fa fa-home" />
            </Link>
          </li>
          <li className="separator">
            <i className="fas fa-chevron-right" />
          </li>
          <li className="nav-item">
            <Link to="/gioi-thieu">Giới Thiệu Đầu Tư</Link>
          </li>
        </ul>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="card">
            <div className="card-header">
              <div className="card-title">
                Danh Sách Nhà Đầu Tư Đã Giới Thiệu
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        <b>Tên Tài Khoản</b>
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        <b>Họ &amp; Tên</b>
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        <b>Tổng Tiền Nạp</b>
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        <b>Đang Đầu Tư</b>
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        <b>Thời Gian Mở Tài Khoản</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.map((item) => (
                      <tr key={item?.id}>
                        <td>{item?.username}</td>
                        <td>{item?.full_name}</td>
                        <td>{formatNumber(item?.bill_deposit)}</td>
                        <td>{formatNumber(item?.bill_fund)}</td>
                        <td>
                          {moment(item?.created_at).format("DD/MM/YYYY HH:mm")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Giới Thiệu Nhà Đầu Tư</div>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="InputLink">
                  Link Giới Thiệu
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="InputLink"
                      value={user?.link_ref}
                      readOnly
                    />
                    <div className="input-group-prepend">
                      <button
                        className="btn btn-secondary"
                        onClick={() => handleCopyLink(user?.link_ref)}
                        type="button"
                      >
                        <span>
                          <i aria-hidden="true" className="icon-docs" /> Sao
                          Chép
                        </span>
                      </button>
                    </div>
                  </div>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="InputMa">
                  Mã Giới Thiệu
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="InputMa"
                      value={user?.ref_no}
                      readOnly
                    />
                    <div className="input-group-prepend">
                      <button
                        className="btn btn-secondary"
                        onClick={() => handleCopyLink(user?.ref_no)}
                        type="button"
                      >
                        <span>
                          <i aria-hidden="true" className="icon-docs" /> Sao
                          Chép
                        </span>
                      </button>
                    </div>
                  </div>
                </label>
              </div>
              <br />
              <p style={{ color: "rgba(169, 175, 187, 0.82)" }}>
                Nhà đầu tư mới đăng ký qua&nbsp;
                <span style={{ fontWeight: "bolder" }}>
                  Link Giới Thiệu&nbsp;
                </span>
                hoặc&nbsp;
                <span style={{ fontWeight: "bolder" }}>Mã Giới Thiệu </span>của
                người giới thiệu, người giới thiệu sẽ được nhận <b>5%</b> tên
                tổng số tiền đầu tư của nhà đầu tư mới.
              </p>
              <br />
              <p style={{ color: "rgba(169, 175, 187, 0.82)" }}>
                Số tiền hoa hồng sẽ được cộng trực tiếp vào tài khoản của người
                giới thiệu.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Present;
