import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { FundApi } from "states/api/fund";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

import FundItem from "./FundItem";

const Invest = observer(() => {
  const {
    authStore: { general },
  } = useStores();

  const { data, refetch } = useQuery(
    ["fund", "list"],
    () =>
      FundApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 60000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="page-inner">
      <div className="page-header">
        <h4 className="page-title">Dự Án Đầu Tư</h4>
        <ul className="breadcrumbs">
          <li className="nav-home">
            <Link to="/">
              <i className="fa fa-home" />
            </Link>
          </li>
          <li className="separator">
            <i className="fas fa-chevron-right" />
          </li>
          <li className="nav-item">
            <Link to="/dau-tu">Dự Án Đầu Tư</Link>
          </li>
        </ul>
      </div>
      <div className="card" style={{ color: "rgba(169, 175, 187, 0.82)" }}>
        <div className="card-header">
          <div className="card-title">Hướng Dẫn Đầu Tư</div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="form-group">
                <p>
                  Nhà đầu tư chọn gói đầu tư và xác nhận gói đầu tư, lợi nhuận
                  sẽ được trả về tài khoản chính mỗi ngày. Sau khi hết hạn gói
                  đầu tư, vốn đầu tư sẽ được hoàn trả về tài khoản của Nhà đầu
                  tư.
                </p>
                <br />
                <p>
                  Nhà đầu tư có thể đăng ký cùng lúc không giới hạn gói đầu tư.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center align-items-center mb-1">
        {data?.data?.map((item) => (
          <FundItem item={item} key={item?.id} />
        ))}
      </div>
    </div>
  );
});

export default Invest;
