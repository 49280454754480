import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ProfileApi } from "states/api";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { BankApi } from "states/api/bank";
import { optionBank } from "_common/constants/bank";

const schema = yup
  .object({
    bank_code: yup.string().required("Bắt buộc điền"),
    bank_owner: yup.string().required("Bắt buộc điền"),
    bank_number: yup.string().required("Bắt buộc điền"),
  })
  .required();

export default function FormBank() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { clear },
  } = useStores();
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data, refetch } = useQuery(
    ["bank", "my-bank"],
    () => BankApi.getMyBank(),
    {
      staleTime: 300000,
    }
  );

  const disabled = useMemo(() => !!data?.id, [data]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!data) return;
    if (!data?.bank_number) return;
    setValue("bank_code", data?.bank_code);
    setValue("bank_name", data?.bank_name);
    setValue("bank_owner", data?.bank_owner);
    setValue("bank_number", data?.bank_number_display);
  }, [data]);

  const { mutate, isLoading } = useMutation(
    (variables) => {
      if (data?.id) {
        return BankApi.update({
          id: data?.id,
          params: {
            ...variables,
          },
        });
      }
      return BankApi.create({
        params: {
          ...variables,
        },
      });
    },
    {
      onSuccess: (res) => {
        reset();
        queryClient.invalidateQueries(["bank", "my-bank"]);
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(res?.data?.msg || "Thao tác thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSubmit = (values) => {
    const bank = optionBank?.find((obj) => obj.value === values?.bank_code);
    mutate({ ...values, bank_name: bank?.text });
  };

  return (
    <form className="form_vertical" onSubmit={handleSubmit(onSubmit)}>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="form-group">
              <label htmlFor="password">
                Tên ngân hàng
                <div className="input">
                  <select
                    className="form-control"
                    {...register("bank_code")}
                    disabled={disabled}
                  >
                    {optionBank.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.text} ({item.sortName})
                      </option>
                    ))}
                  </select>
                </div>
              </label>
            </div>
          </div>
          <div className="col-md-12 col-lg-12">
            <div className="form-group">
              <label htmlFor="password">
                Tên chủ tài khoản
                <div className="input">
                  <input
                    id="bank_owner"
                    className="form-control"
                    type="text"
                    {...register("bank_owner")}
                    disabled={disabled}
                  />
                </div>
              </label>
            </div>
          </div>
          <div className="col-md-12 col-lg-12">
            <div className="form-group">
              <label htmlFor="password">
                Số tài khoản ngân hàng
                <div className="input">
                  <input
                    id="bank_number"
                    className="form-control"
                    type="text"
                    {...register("bank_number")}
                    disabled={disabled}
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      {!disabled && (
        <div className="card-action">
          <button type="submit" className="btn btn-info">
            Lưu Thay Đổi
          </button>
        </div>
      )}
    </form>
  );
}
