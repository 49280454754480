import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProfileApi } from "states/api";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";

const schema = yup
  .object({
    password: yup.string(),
    new_password: yup.string().required("Bắt buộc! Vui lòng nhập"),
    confirm_password: yup.string().required("Bắt buộc! Vui lòng nhập"),
  })
  .required();

export default function FormWalletPassword() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user },
  } = useStores();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutate, isLoading } = useMutation(
    (variables) =>
      ProfileApi.changeWalletPassword({
        params: {
          ...variables,
        },
      }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(res?.msg || "Thay đổi mật khẩu thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Lỗi trong quá trình thực hiện";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSubmit = (values) => {
    mutate(values);
  };

  return (
    <form className="form_vertical" onSubmit={handleSubmit(onSubmit)}>
      <div className="card-body">
        <div className="row">
          {user?.wallet_password && (
            <div className="col-md-12 col-lg-12">
              <div className="form-group">
                <label htmlFor="password">
                  Mật Khẩu Hiện Tại
                  <div className="input-icon">
                    <span className="input-icon-addon">
                      <i className="fas fa-key" />
                    </span>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Nhập mật khẩu hiện tại..."
                      {...register("password")}
                    />
                  </div>
                </label>
              </div>
            </div>
          )}

          <div className="col-md-6 col-lg-6">
            <div className="form-group">
              <label htmlFor="password">
                Mật Khẩu Mới
                <div className="input-icon">
                  <span className="input-icon-addon">
                    <i className="fas fa-key" />
                  </span>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Nhập mật khẩu mới..."
                    id="new_password"
                    {...register("new_password")}
                  />
                </div>
              </label>
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="form-group">
              <label htmlFor="password">
                Nhập Lại Mật Khẩu Mới
                <div className="input-icon">
                  <span className="input-icon-addon">
                    <i className="fas fa-key" />
                  </span>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Nhập lại mật khẩu mới..."
                    id="confirm_password"
                    {...register("confirm_password")}
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="card-action">
        <button type="submit" className="btn btn-info">
          Lưu Thay Đổi
        </button>
      </div>
    </form>
  );
}
