import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { UserFundApi } from "states/api/userFund";
import { get } from "lodash";

const ModalInvest = observer(({ item, dataProfit, onClose }) => {
  const queryClient = useQueryClient();
  const {
    authStore: { general },
  } = useStores();

  const { mutate, isLoading } = useMutation(
    () =>
      UserFundApi.receive({
        params: {
          amount: item?.invest_amount,
          fund_id: item?.id,
          time: dataProfit?.time,
          profit: dataProfit?.profit,
        },
      }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(res?.data?.msg || "Thao tác thành công");
        onClose();
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  return (
    <div className="modal fade show" style={{ display: "block" }}>
      <div
        className="modal-dialog modal-dialog-centered"
        onClick={() => onClose()}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" style={{ color: "#000000FF" }}>
              Xác Nhận Đầu Tư Dự Án
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <p style={{ color: "#000000FF" }}>
              Bạn có đồng ý đầu tư gói{" "}
              <b style={{ color: "blue" }}>{item?.name}</b> với vốn đầu tư là{" "}
              <b style={{ color: "red" }}>
                {formatNumber(item?.invest_amount)} VNĐ
              </b>{" "}
              không?
            </p>
            <p style={{ color: "#000000FF" }}>
              Sau khi xác nhận gói đầu tư, vốn đầu tư của bạn sẽ được chuyển đến
              Chủ đầu tư trong thời gian{" "}
              <b style={{ color: "orange" }}>{dataProfit?.time}</b> ngày với lợi
              nhuận{" "}
              <b style={{ color: "orange" }}>
                {formatNumber(dataProfit?.profit, "0,0.[00]")}%
              </b>
              .
            </p>
            <p style={{ color: "#000000FF" }}>
              Ước tính lợi nhuận là{" "}
              <b style={{ color: "orange" }}>
                {formatNumber(dataProfit?.profit_est)}
              </b>{" "}
              VNĐ/ngày.
            </p>
            <p style={{ color: "#000000FF" }}>
              Sau <b style={{ color: "orange" }}>{dataProfit?.time}</b> ngày,
              vốn đầu tư sẽ được hoàn trả về tài khoản của bạn. và lợi nhuận thu
              được là{" "}
              <b style={{ color: "orange" }}>
                {formatNumber(
                  get(dataProfit, "profit_est", 0) * get(dataProfit, "time", 0)
                )}
              </b>{" "}
              VNĐ.
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Đóng
            </button>

            <button
              type="button"
              onClick={() => mutate()}
              className="btn btn-primary"
              name="submit_1"
            >
              Xác Nhận Đầu Tư
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModalInvest;
