import { endpoint } from "_common/constants/common";
import api from "states/drivers";

const FundEndpoint = `${endpoint}/fund`;

export const FundApi = {
  getList: async ({ params }) =>
    await api
      .get(`${FundEndpoint}/list`, params)
      .then((result) => result.data?.data),
  getDetail: async ({ id }) =>
    await api.get(`${FundEndpoint}/detail/${id}`).then((result) => result.data),
  receive: async ({ id, params }) =>
    await api
      .post(`${FundEndpoint}/receive/${id}`, params)
      .then((result) => result.data),
};
