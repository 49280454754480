import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";

const Login = observer(() => {
  const {
    authStore: { general },
  } = useStores();

  return (
    <div className="page-inner">
      <div className="page-header">
        <h4 className="page-title">Đăng Nhập Tài Khoản</h4>
        <ul className="breadcrumbs">
          <li className="nav-home">
            <a href="/home">
              <i className="fa fa-home" />
            </a>
          </li>
          <li className="separator">
            <i className="fas fa-chevron-right" />
          </li>
          <li className="nav-item">
            <a href="/dang-nhap/">Đăng Nhập Tài Khoản</a>
          </li>
        </ul>
      </div>
      <form action="" method="post">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Đăng Nhập Tài Khoản</div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="form-group">
                    <label htmlFor="email2">
                      Tên Đăng Nhập
                      <div className="input-icon">
                        <span className="input-icon-addon">
                          <i className="fa fa-user" />
                        </span>
                        <input
                          id="email2"
                          type="text"
                          className="form-control"
                          name="username"
                          placeholder="Nhập tên đăng nhập..."
                          required=""
                        />
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="form-group">
                    <label htmlFor="password">
                      Mật Khẩu
                      <div className="input-icon">
                        <span className="input-icon-addon">
                          <i className="fas fa-key" />
                        </span>
                        <input
                          id="password"
                          type="password"
                          className="form-control"
                          name="password"
                          placeholder="Nhập mật khẩu..."
                          required=""
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-action">
              <button type="submit" name="dangnhap" className="btn btn-danger">
                Đăng Nhập
              </button>
              <a type="button" href="/dang-ky/" className="btn btn-info">
                Đăng Ký Mở Tài Khoản
              </a>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
});

export default Login;
