import React from "react";
import "react-notifications/lib/notifications.css";
import "swiper/swiper-bundle.css";
import Root from "./Root";
import "./assets/css/bootstrap.min.css";
import "./assets/css/atlantis.min.css";
import "./assets/css/fonts.min.css";
import "./assets/scss/style.scss";

function App() {
  return <Root />;
}

export default App;
