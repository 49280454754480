import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import PageLoading from "_common/component/PageLoading";
import { useQuery } from "@tanstack/react-query";
import { AuthApi } from "states/api";

import MobileLayout from "./MobileLayout";

const BasicLayout = observer(() => {
  const {
    authStore: { token, authenticated, updateUser },
  } = useStores();

  useQuery(["get_profile"], AuthApi.getProfile, {
    staleTime: 300000,
    enabled: !!token,
    onSuccess: (res) => {
      updateUser({ user: res?.data });
    },
  });

  if (!authenticated) return <PageLoading />;
  return <MobileLayout />;
});

export default BasicLayout;
