import React from "react";
import { useLocation } from "react-router-dom";
import { useStores } from "_common/hooks";

export default function MobileFooter({ toggleMenu, toggleCollapsed, menus }) {
  const {
    authStore: { user, general },
  } = useStores();
  const location = useLocation();

  return (
    <footer className="footer">
      <div className="container-fluid">
        <nav className="pull-left">
          <ul className="nav">
            {/* <li className="nav-item">
              <a className="nav-link" href="/">
                Fanpage
              </a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" href={general?.link_support}>
                Telegram
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright" style={{ marginLeft: "auto" }}>
          Bản quyền © 2023 thuộc sở hữu &amp; quản lý bởi Capitaland
        </div>
      </div>
    </footer>
  );
}
