import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";
import { formatNumber } from "_common/utils/formatValue";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PaymentApi } from "states/api/payment";
import { NotificationManager } from "react-notifications";

const Deposit = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { general, user, bank },
  } = useStores();

  const [valueDeposit, setValuePosit] = useState("");

  const minAmount = useMemo(
    () => (general?.min_deposit ? Number(general?.min_deposit) : 1),
    [general]
  );

  const maxAmount = useMemo(
    () =>
      general?.max_deposit
        ? Number(general?.max_deposit)
        : 10000000000000000000,
    [general]
  );

  const { mutate, isLoading } = useMutation(
    (variables) =>
      PaymentApi.deposit({
        params: {
          ...variables,
          amount: valueDeposit,
          token_id: 3,
          note: user?.username,
          type: "deposit",
        },
      }),
    {
      onSuccess: (res) => {
        setValuePosit("");
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(res?.data?.msg || "Thao tác thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleSubmit = () => {
    if (!valueDeposit || valueDeposit <= 0)
      return NotificationManager.error("Vui lòng nhập số tiền");
    mutate();

    return null;
  };

  const handleChangeValuePosit = (e) => {
    setValuePosit(e?.target?.value);
  };

  return (
    <form method="POST" action="">
      <div className="card">
        <div className="card-header">
          <div className="card-title">Nạp Tiền Tài Khoản</div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="form-group">
                <h5>Số tiền</h5>
                <input
                  type="number"
                  placeholder={`Số tiền nạp ${formatNumber(
                    minAmount
                  )} ~ ${formatNumber(maxAmount)}`}
                  value={valueDeposit}
                  onChange={handleChangeValuePosit}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-12">
              <div className="form-group">
                <p>
                  Số tiền yêu cầu nạp là:{" "}
                  <b className="text-red">{formatNumber(valueDeposit)}VNĐ</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="card-action">
          <button
            type="button"
            onClick={() => handleSubmit()}
            className="btn btn-secondary"
          >
            Xác Nhận Nạp Tiền
          </button>
        </div>
      </div>
    </form>
  );
});

export default Deposit;
