import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { SystemBankApi } from "states/api/systemBank";
import { handleCopyLink } from "_common/utils/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

import Deposit from "./Deposit";

const Recharge = observer(() => {
  const {
    authStore: { general, user },
  } = useStores();

  const { data, refetch } = useQuery(
    ["bank_system", "detail"],
    () =>
      SystemBankApi.getDetail({
        params: {
          type: "system",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  return (
    <div className="page-inner">
      <div className="page-header">
        <h4 className="page-title">Nạp Tiền Tài khoản</h4>
        <ul className="breadcrumbs">
          <li className="nav-home">
            <Link href="/">
              <i className="fa fa-home" />
            </Link>
          </li>
          <li className="separator">
            <i className="fas fa-chevron-right" />
          </li>
          <li className="nav-item">
            <Link to="/nap-tien">Nạp Tiền Tài khoản</Link>
          </li>
        </ul>
      </div>
      <div className="row">
        <div className="col-md-7">
          <Deposit />
        </div>
        <div className="col-md-5">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Nội dung nạp tiền</div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        value={`${general?.deposit_code} ${user?.username}`}
                        readOnly
                      />
                      <div className="input-group-prepend">
                        <button
                          className="btn btn-secondary"
                          onClick={() =>
                            handleCopyLink(
                              `${general?.deposit_code} ${user?.username}`
                            )
                          }
                          type="button"
                        >
                          <span>
                            <i aria-hidden="true" className="icon-docs" />
                            Sao Chép
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="form-group">
                    <p style={{ color: "rgba(169, 175, 187, 0.82)" }}>
                      Lưu ý: Vui lòng nhập đúng nội dung giao dịch để hệ thống
                      tự động cộng tiền vào tài khoản của bạn!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Hướng Dẫn Nạp Tiền</div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="form-group">
                    <p style={{ color: "rgba(169, 175, 187, 0.82)" }}>
                      Nhà đầu tư vui lòng nhập chính xác nội dung giao dịch
                      là&nbsp;
                      <span style={{ fontWeight: "bolder" }}>
                        {general?.deposit_code} + tên đăng nhập
                      </span>
                      &nbsp;để hệ thống tự động cộng tiền vào tài khoản.
                    </p>
                    <br />
                    <p style={{ color: "rgba(169, 175, 187, 0.82)" }}>
                      Nếu sau 5 phút tài khoản chưa được cộng tiền, vui lòng
                      liên hệ CKSH để được hỗ trợ.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Chuyển Khoản Ngân Hàng</div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div
                    className="form-group"
                    style={{ color: "rgba(169, 175, 187, 0.82)" }}
                  >
                    <ul>
                      <li>
                        Tên Ngân Hàng:{" "}
                        <b style={{ color: "red" }}>{data?.bank_code}</b>
                      </li>
                      <li>
                        Số Tài Khoản:{" "}
                        <b style={{ color: "red" }}>{data?.bank_number}</b>{" "}
                        <FontAwesomeIcon
                          icon={faCopy}
                          onClick={() => handleCopyLink(data?.bank_number)}
                        />
                      </li>
                      <li>
                        Chủ Tài Khoản:{" "}
                        <b style={{ color: "yellow" }}>{data?.bank_owner}</b>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Recharge;
