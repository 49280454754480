import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useMutation } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";

import { AuthApi } from "states/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStores } from "_common/hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

const schema = yup
  .object({
    username: yup.string().required("Bắt buộc nhập"),
    password: yup.string().required("Bắt buộc nhập"),
  })
  .required();

const LoginPage = observer(() => {
  const { authStore } = useStores();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.login(variables),
    {
      onSuccess: (res) => {
        authStore.updateUser(res?.data);
        authStore.updateToken(res?.data?.token);
        setTimeout(() => {
          navigate("/");
        }, 200);
      },
      onError: (error) => {
        const errorMessage =
          error?.message ?? "Xảy ra lỗi trong quá trình thực hiện";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  useEffect(() => {
    setTimeout(() => {
      reset();
    }, 1000);
  }, []);

  return (
    <div className="page-inner">
      <div className="page-header">
        <h4 className="page-title">Đăng Nhập Tài Khoản</h4>
      </div>
      <form onSubmit={handleSubmit(handleLogin)} autoComplete="none">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Đăng Nhập Tài Khoản</div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="form-group">
                    <label htmlFor="email2">
                      Tên Đăng Nhập
                      <div className="input-icon">
                        <span className="input-icon-addon">
                          <i className="fa fa-user" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          name="username"
                          placeholder="Nhập tên đăng nhập..."
                          required
                          autoComplete="none"
                          {...register("username")}
                        />
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="form-group">
                    <label htmlFor="password">
                      Mật Khẩu
                      <div className="input-icon">
                        <span className="input-icon-addon">
                          <i className="fas fa-key" />
                        </span>
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          placeholder="Nhập mật khẩu..."
                          autoComplete="off"
                          required
                          {...register("password")}
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-action">
              <button type="submit" name="dangnhap" className="btn btn-danger">
                Đăng Nhập
              </button>
              <Link to="/auth/register" className="btn btn-info">
                Đăng Ký Mở Tài Khoản
              </Link>
            </div>
          </div>
        </div>
      </form>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        centeredSlides
        loop
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="swiper2"
      >
        {authStore?.banners?.map((item, index) => (
          <SwiperSlide key={`index-${index?.toString()}`}>
            <img src={item} alt="" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
});

export default LoginPage;
