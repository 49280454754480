import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";
import { formatNumber } from "_common/utils/formatValue";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PaymentApi } from "states/api/payment";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { paymentStatusText } from "_common/constants/statusType";

const History = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { general, user, bank },
  } = useStores();

  const [params, setParams] = useState();

  const { data, isLoading, refetch } = useQuery(
    ["payment", "list_withdraw", params],
    () =>
      PaymentApi.getList({
        params: {
          type: "withdraw",
          page: 1,
          limit: 30,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title">Lịch Sử Rút Tiền</div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="form-group">
              <div className="table-responsive">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr role="row">
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        <b>Mã Giao Dịch</b>
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        <b>Tên Ngân Hàng</b>
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        <b>Số Tài Khoản</b>
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        <b>Chủ Tài Khoản</b>
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        <b>Số Tiền Rút</b>
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        <b>Thời Gian Thực Hiện</b>
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        <b>Trạng Thái</b>
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        <b>Ghi Chú</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.map((item) => (
                      <tr key={item?.id}>
                        <td>{item?.ref_no}</td>
                        <td>{item?.bank?.bank_code}</td>
                        <td>{item?.bank?.bank_number_display}</td>
                        <td>{item?.bank?.bank_owner}</td>
                        <td>{formatNumber(item?.final_total)}</td>
                        <td>
                          {moment(item?.created_at).format("DD/MM/YYYY HH:mm")}
                        </td>
                        <td>{paymentStatusText[item?.status]}</td>
                        <td>{item?.reason}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default History;
