import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";

import { formatNumber } from "_common/utils/formatValue";
import ModalInvest from "./ModalInvest";

const FundItem = observer(({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTime, setActiveTime] = useState(item?.time);

  const dataProfit = useMemo(() => {
    switch (activeTime) {
      case item?.time:
        return {
          time: item?.time,
          profit: item?.profit,
          profit_est: item?.profit_est,
        };
      case item?.time2:
        return {
          time: item?.time2,
          profit: item?.profit2,
          profit_est: item?.profit2_est,
        };
      case item?.time3:
        return {
          time: item?.time3,
          profit: item?.profit3,
          profit_est: item?.profit3_est,
        };
      default:
        return {
          time: 0,
          profit: 0,
          profit_est: 0,
        };
    }
  }, [item, activeTime]);

  return (
    <>
      <div className="col-md-4 pl-md-0" key={item?.id}>
        <div className="card card-pricing">
          <div className="card-header">
            <h4 className="card-title">{item?.name}</h4>
            <div className="card-price">
              <span className="price">{formatNumber(item?.invest_amount)}</span>
              <span className="text"> VNĐ</span>
            </div>
          </div>
          <div className="card-body">
            <ul className="specification-list">
              <li>
                <img src={item?.avatar_url} alt="" />
              </li>
              <li>
                <span className="name-specification">Thời Hạn</span>
                <span className="status-specification">
                  {formatNumber(dataProfit?.time)} Ngày
                </span>
              </li>
              <li>
                <span className="name-specification">Lãi Suất</span>
                <span className="status-specification">
                  {formatNumber(dataProfit?.profit, "0,0.[0]")}%/Ngày
                </span>
              </li>
              <li>
                <span className="name-specification">Lợi Nhuận Ước Tính</span>
                <span className="status-specification">
                  {formatNumber(dataProfit?.profit_est)} VNĐ/Ngày
                </span>
              </li>
              <li>
                <span className="name-specification">Chủ Đầu Tư</span>
                <span className="status-specification">{item?.owner}</span>
              </li>
              <li>
                <div className="list-time">
                  {item?.time && (
                    <span
                      className={classNames("times", {
                        active: activeTime === item?.time,
                      })}
                      onClick={() => setActiveTime(item?.time)}
                    >
                      {formatNumber(item?.profit, "0,0.[00]")}% / {item?.time}{" "}
                      ngày
                    </span>
                  )}
                  {item?.time2 && (
                    <span
                      className={classNames("times", {
                        active: activeTime === item?.time2,
                      })}
                      onClick={() => setActiveTime(item?.time2)}
                    >
                      {formatNumber(item?.profit2, "0,0.[00]")}% / {item?.time2}{" "}
                      ngày
                    </span>
                  )}
                  {item?.time3 && (
                    <span
                      className={classNames("times", {
                        active: activeTime === item?.time3,
                      })}
                      onClick={() => setActiveTime(item?.time3)}
                    >
                      {formatNumber(item?.profit3, "0,0.[00]")}% / {item?.time3}{" "}
                      ngày
                    </span>
                  )}
                </div>
              </li>
            </ul>
          </div>
          <div className="card-footer">
            <button
              type="button"
              className="btn btn-primary btn-block"
              onClick={() => setIsOpen(true)}
            >
              Chọn Gói Đầu Tư
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <ModalInvest
          item={item}
          dataProfit={dataProfit}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
});

export default FundItem;
