export const statusWorkText = {
  0: "Tất cả",
  1: "Đang làm việc",
  2: "Nghỉ chờ việc",
  3: "Nghỉ tự túc",
  4: "Nghỉ việc",
};

export const statusWorkColor = {
  1: "#33cc33",
  2: "#e6b800",
  3: "#660033",
  4: "#cc0000",
};

export const laborContractTypeText = {
  4: "Xác định thời điểm",
  5: "Không xác định",
};

export const genderText = {
  1: "Nam",
  2: "Nữ",
};

export const statusText = {
  0: "Ngừng hoạt động",
  1: "Hoạt động",
};

export const statusColor = {
  0: "#cc0000",
  1: "#33cc33",
};

export const permissionText = {
  1: "Quản trị viên",
  2: "Người dùng",
};

export const paymentStatusText = {
  pending: "Đang chờ",
  complete: "Hoàn thành",
  error: "Từ chối",
};
export const DiceText = {
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
};

export const gameBetText = {
  big: "Lớn",
  small: "Nhỏ",
  odd: "Lẻ",
  even: "Chẵn",
  single1: "Đơn 1",
  single2: "Đơn 2",
  single3: "Đơn 3",
  single4: "Đơn 4",
  single5: "Đơn 5",
  single6: "Đơn 6",
  double1: "1:1",
  double2: "2:2",
  double3: "3:3",
  double4: "4:4",
  double5: "5:5",
  double6: "6:6",
  trible1: "1:1:1",
  trible2: "2:2:2",
  trible3: "3:3:3",
  trible4: "4:4:4",
  trible5: "5:5:5",
  trible6: "6:6:6",
  trible_all: "3xx 1:20",
};

export const gameStatusText = {
  win: "Chiên thắng",
  loss: "Thất bại",
  betGame: "Chờ giải thưởng",
};

export const fundStatusText = {
  pending: "Đang góp vốn",
  complete: "Kết thúc",
  error: "Lỗi",
};

export const paymentAddText = {
  withdraw: "-",
  deposit: "+",
  commission: "+",
  fund: "-",
  add_money: "+",
  sub_money: "-",
};
