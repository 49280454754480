import React, { useState } from "react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import { formatNumber } from "_common/utils/formatValue";

function HeaderTop({ isBack = true, title }) {
  const {
    authStore: { user, clear, general, authenticated, logo_white },
  } = useStores();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage = error?.message ?? "Vui lòng thử lại!";
      NotificationManager.error(errorMessage);
    },
  });

  const [isShowUserDropdownn, setIsShowUserDropdown] = useState(false);

  const toggleSidebar = () => {
    const [wrapper] = document.getElementsByClassName("wrapper");
    const [btnToggle] = document.getElementsByClassName("toggle-sidebar");

    if (!btnToggle.classList.contains("toggled")) {
      wrapper.classList.add("sidebar_minimize");
      btnToggle.classList.add("toggled");
      btnToggle.innerHTML = `<i class="icon-options-vertical"/>`;
    } else {
      wrapper.classList.remove("sidebar_minimize");
      btnToggle.classList.remove("toggled");
      btnToggle.innerHTML = `<i class="icon-menu"/>`;
    }
  };

  const toggleSideNav = () => {
    const [html] = document.getElementsByTagName("html");
    const [btnToggle] = document.getElementsByClassName("sidenav-toggler");

    if (!btnToggle.classList.contains("toggled")) {
      html.classList.add("nav_open");
      btnToggle.classList.add("toggled");
    } else {
      html.classList.remove("nav_open");
      btnToggle.classList.remove("toggled");
    }
  };

  const toggleTopbar = () => {
    const [html] = document.getElementsByTagName("html");
    const [btnToggle] = document.getElementsByClassName("topbar-toggler");

    if (!btnToggle.classList.contains("toggled")) {
      html.classList.add("topbar_open");
      btnToggle.classList.add("toggled");
    } else {
      html.classList.remove("topbar_open");
      btnToggle.classList.remove("toggled");
    }
  };

  const toggleUserDropdown = () => {
    setIsShowUserDropdown(!isShowUserDropdownn);
  };

  return (
    <div className="main-header">
      <div className="logo-header" data-background-color="dark2">
        <a href="/" className="logo">
          <img src={logo_white} alt="navbar brand" className="navbar-brand" />
          <b style={{ color: "white" }}>{general?.name_website}</b>
        </a>
        <button
          className="navbar-toggler sidenav-toggler ml-auto"
          type="button"
          data-toggle="collapse"
          data-target="collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleSideNav}
        >
          <span className="navbar-toggler-icon">
            <i className="icon-menu" />
          </span>
        </button>
        <button
          className="topbar-toggler more"
          type="button"
          onClick={toggleTopbar}
        >
          <i className="icon-options-vertical" />
        </button>
        <div className="nav-toggle">
          <button
            className="btn btn-toggle toggle-sidebar"
            type="button"
            onClick={toggleSidebar}
          >
            <i className="icon-menu" />
          </button>
        </div>
      </div>
      <nav
        className="navbar navbar-header navbar-expand-lg"
        data-background-color="dark"
      >
        <div className="container-fluid">
          {authenticated && (
            <div className="collapse" id="search-nav">
              <form className="navbar-left navbar-form nav-search mr-md-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <button type="submit" className="btn btn-search pr-1">
                      <FontAwesomeIcon
                        icon={faSearch}
                        className="search-icon"
                      />
                    </button>
                  </div>
                  <input
                    type="text"
                    placeholder="Tìm kiếm..."
                    className="form-control"
                  />
                </div>
              </form>
            </div>
          )}

          <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
            {authenticated && (
              <li>
                <span className="balance">
                  {formatNumber(user?.balance)}VNĐ
                </span>
              </li>
            )}

            <li className="nav-item dropdown hidden-caret submenu show">
              {authenticated && (
                <span
                  className="dropdown-toggle profile-pic"
                  onClick={toggleUserDropdown}
                  style={{ cursor: "pointer" }}
                >
                  <div className="avatar-sm">
                    <img
                      src={user?.avatar_url}
                      alt="..."
                      className="avatar-img rounded-circle"
                    />
                  </div>
                </span>
              )}

              <ul
                className={`dropdown-menu dropdown-user animated fadeIn ${
                  isShowUserDropdownn ? "show" : ""
                }`}
              >
                <div
                  className="scroll-wrapper dropdown-user-scroll scrollbar-outer"
                  style={{ position: "relative" }}
                >
                  <div
                    className="dropdown-user-scroll scrollbar-outer scroll-content"
                    style={{
                      height: "184.1px",
                      marginBottom: "0px",
                      marginRight: "0px",
                      maxHeight: "none",
                    }}
                  >
                    <li>
                      <div className="user-box">
                        <div className="avatar-lg">
                          <img
                            src={logo_white}
                            alt="admin"
                            className="avatar-img rounded"
                          />
                        </div>
                        <div className="u-text">
                          <h4>{user?.username}</h4>
                          <p className="text-muted">{user?.email}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dropdown-divider" />
                      <Link className="dropdown-item" to="/profile">
                        Thông Tin Cá Nhân
                      </Link>
                      <Link className="dropdown-item" to="/them-ngan-hang">
                        Ngân hàng của tôi
                      </Link>
                      <Link className="dropdown-item" to="/my-investment">
                        Lịch Sử Đầu Tư
                      </Link>
                      <div className="dropdown-divider" />
                      <div className="dropdown-item" onClick={() => onLogout()}>
                        Đăng Xuất
                      </div>
                    </li>
                  </div>
                  <div className="scroll-element scroll-x">
                    <div className="scroll-element_outer">
                      <div className="scroll-element_size" />
                      <div className="scroll-element_track" />
                      <div
                        className="scroll-bar ui-draggable ui-draggable-handle"
                        style={{ width: "100px" }}
                      />
                    </div>
                  </div>
                  <div className="scroll-element scroll-y">
                    <div className="scroll-element_outer">
                      <div className="scroll-element_size" />
                      <div className="scroll-element_track" />
                      <div
                        className="scroll-bar ui-draggable ui-draggable-handle"
                        style={{ height: "100px" }}
                      />
                    </div>
                  </div>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default HeaderTop;
