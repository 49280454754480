import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import moment from "moment";
import { formatNumber } from "_common/utils/formatValue";
import FormBank from "pages/Bank/FormBank";
import FormChangePassword from "./FormChangePassword";
import FormWalletPassword from "./FormWalletPassword";

const Profile = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="page-inner">
      <div className="page-header">
        <h4 className="page-title">Thông Tin Cá Nhân</h4>
        <ul className="breadcrumbs">
          <li className="nav-home">
            <a href="/home">
              <i className="fa fa-home" />
            </a>
          </li>
          <li className="separator">
            <i className="fas fa-chevron-right" />
          </li>
          <li className="nav-item">
            <a href="/profile">Thông Tin Cá Nhân</a>
          </li>
        </ul>
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <div className="card-title">Thông Tin Cá Nhân</div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="form-group">
                  <label htmlFor="email2">
                    Họ &amp; Tên
                    <div className="input-icon">
                      <span className="input-icon-addon">
                        <i className="fa fa-user" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={user?.full_name}
                        readOnly
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-md-12 col-lg-12">
                <div className="form-group">
                  <label htmlFor="email2">
                    Số Điện Thoại
                    <div className="input-icon">
                      <span className="input-icon-addon">
                        <i className="fa fa-user" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={user?.phone}
                        readOnly
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-md-12 col-lg-12">
                <div className="form-group">
                  <label htmlFor="email2">
                    CCCD/Hộ Chiếu
                    <div className="input-icon">
                      <span className="input-icon-addon">
                        <i className="fa fa-user" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={user?.identify_number}
                        readOnly
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-md-12 col-lg-12">
                <div className="form-group">
                  <label htmlFor="email2">
                    Tên Đăng Nhập
                    <div className="input-icon">
                      <span className="input-icon-addon">
                        <i className="fa fa-user" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={user?.username}
                        readOnly
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-md-12 col-lg-12">
                <div className="form-group">
                  <label htmlFor="email2">
                    Thời Gian Mở Tài Khoản
                    <div className="input-icon">
                      <span className="input-icon-addon">
                        <i className="fas fa-calendar-alt" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={moment(user?.created_at).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                        readOnly
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-md-12 col-lg-12">
                <div className="form-group">
                  <label htmlFor="email2">
                    Cấp độ thành viên
                    <div className="input-icon">
                      <span className="input-icon-addon">
                        <i className="icon-people" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={user?.level_text}
                        readOnly
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-md-12 col-lg-12">
                <div className="form-group">
                  <label htmlFor="email2">
                    Người Giới Thiệu
                    <div className="input-icon">
                      <span className="input-icon-addon">
                        <i className="icon-people" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={user?.user_ref?.username}
                        readOnly
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-md-12 col-lg-12">
                <div className="form-group">
                  <label htmlFor="email2">
                    Số Dư Hiện Tại
                    <div className="input-icon">
                      <span className="input-icon-addon">
                        <i className="fas fa-money-bill" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={`${formatNumber(user?.balance)} VNĐ`}
                        readOnly
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <div className="card-title">Thay Đổi Mật Khẩu</div>
          </div>
          <FormChangePassword />
        </div>
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <div className="card-title">Thay Đổi Mật Rút tiền</div>
          </div>
          <FormWalletPassword />
        </div>
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <div className="card-title">Ngân hàng</div>
          </div>
          <FormBank />
        </div>
      </div>
    </div>
  );
});

export default Profile;
