import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useMutation } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { AuthApi } from "states/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStores } from "_common/hooks";

const schema = yup
  .object({
    username: yup.string().required("Bắt buộc nhập"),
    password: yup.string().required("Bắt buộc nhập"),
  })
  .required();

const RegisterPage = observer(() => {
  const { authStore } = useStores();
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const ref_no = params?.get("ref_no");

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.register(variables),
    {
      onSuccess: (res) => {
        authStore.updateUser(res?.data);
        setTimeout(() => {
          navigate("/");
        }, 200);
      },
      onError: (error) => {
        const errorMessage =
          error?.message ?? "Xảy ra lỗi trong quá trình thực hiện";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  useEffect(() => {
    setValue("ref_no", ref_no);
  }, [ref_no]);

  return (
    <div className="page-inner">
      <div className="page-header">
        <h4 className="page-title">Đăng Ký Mở Tài Khoản</h4>
      </div>
      <form onSubmit={handleSubmit(handleLogin)}>
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Đăng Ký Mở Tài Khoản</div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="form-group">
                    <label htmlFor="full_name">
                      Họ &amp; Tên
                      <div className="input-icon">
                        <span className="input-icon-addon">
                          <i className="fa fa-user" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          name="full_Name"
                          placeholder="Nhập họ &amp; tên..."
                          required
                          {...register("full_Name")}
                        />
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="form-group">
                    <label htmlFor="phone">
                      Số Điện Thoại
                      <div className="input-icon">
                        <span className="input-icon-addon">
                          <i className="fa fa-user" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          name="phone"
                          placeholder="Nhập số điện thoại..."
                          required
                          {...register("phone")}
                        />
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="form-group">
                    <label htmlFor="identify_number">
                      CCCD / Hộ chiếu
                      <div className="input-icon">
                        <span className="input-icon-addon">
                          <i className="fa fa-user" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          name="identify_number"
                          placeholder="Nhập CCCD/ Hộ Chiếu..."
                          required
                          {...register("identify_number")}
                        />
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="form-group">
                    <label htmlFor="username">
                      Tên Đăng Nhập
                      <div className="input-icon">
                        <span className="input-icon-addon">
                          <i className="fa fa-user" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          name="username"
                          placeholder="Nhập tên đăng nhập..."
                          required
                          {...register("username")}
                        />
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="form-group">
                    <label htmlFor="password">
                      Mật Khẩu
                      <div className="input-icon">
                        <span className="input-icon-addon">
                          <i className="fas fa-key" />
                        </span>
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          placeholder="Nhập mật khẩu..."
                          required
                          {...register("password")}
                        />
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="form-group">
                    <label htmlFor="ref_no">
                      Mã Giới Thiệu
                      <div className="input-icon">
                        <span className="input-icon-addon">
                          <i className="icon-people" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          name="ref_no"
                          readOnly={!!ref_no}
                          placeholder="Nhập mã giới thiệu..."
                          {...register("ref_no")}
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-action">
              <button type="submit" name="dangky" className="btn btn-info">
                Mở Tài Khoản
              </button>
            </div>
            <div className="card-action">
              <p className="text-help">
                Bạn đã có tài khoản? <Link to="/auth/login">Đăng nhập</Link>
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
});

export default RegisterPage;
