import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import Notice from "./Notice";

const Home = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="game-page">
      <div className="page-inner">
        <div className="mt-2 mb-4">
          <h2 className="text-white pb-2">
            Xin chào Nhà đầu tư: <b>{user?.username}</b>
          </h2>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="card card-dark bg-primary-gradient">
              <div className="card-body pb-0">
                <div className="p fw-bold float-right">
                  {formatNumber(user?.bill_deposit)} VNĐ
                </div>
                <p className="mb-2">Tổng Tiền Nạp</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card card-dark bg-secondary-gradient">
              <div className="card-body pb-0">
                <div className="p fw-bold float-right">
                  {formatNumber(user?.balance)} VNĐ
                </div>
                <p className="mb-2">Số Dư Hiện Tại</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card card-dark bg-danger">
              <div className="card-body pb-0">
                <div className="p fw-bold float-right">
                  {formatNumber(user?.bill_commission)} VNĐ
                </div>
                <p className="mb-2">Tổng Lợi Nhuận</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card card-dark bg-info">
              <div className="card-body pb-0">
                <div className="p fw-bold float-right">
                  {formatNumber(user?.bill_fund)} VNĐ
                </div>
                <p className="mb-2">Đang Đầu Tư</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="card card-stats card-primary card-round">
              <div className="card-body">
                <div className="row">
                  <div className="col-5">
                    <div className="icon-big text-center">
                      <i className="icon-people" />
                    </div>
                  </div>
                  <div className="col-7 col-stats">
                    <div className="numbers">
                      <p className="card-category">Tổng Giới Thiệu</p>
                      <p className="card-title">
                        {formatNumber(user?.total_ref)} Người
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="card card-stats card-info card-round">
              <div className="card-body">
                <div className="row">
                  <div className="col-5">
                    <div className="icon-big text-center">
                      <i className="fas fa-donate" />
                    </div>
                  </div>
                  <div className="col-7 col-stats">
                    <div className="numbers">
                      <p className="card-category">Tổng Hoa Hồng</p>
                      <p className="card-title">
                        {formatNumber(user?.bill_profit_ref)} VNĐ
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="card card-stats card-success card-round">
              <div className="card-body ">
                <div className="row">
                  <div className="col-5">
                    <div className="icon-big text-center">
                      <i className="fas fa-chart-pie" />
                    </div>
                  </div>
                  <div className="col-7 col-stats">
                    <div className="numbers">
                      <p className="card-category">Tổng Dự Án Đang Đầu Tư</p>
                      <p className="card-title">
                        {formatNumber(user?.total_fund)} Dự Án
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="card card-stats card-danger card-round">
              <div className="card-body ">
                <div className="row">
                  <div className="col-5">
                    <div className="icon-big text-center">
                      <i className="fas fa-clock" />
                    </div>
                  </div>
                  <div className="col-7 col-stats">
                    <div className="numbers">
                      <p className="card-category">Tổng Dự Án Hoàn Thành</p>
                      <p className="card-title">
                        {formatNumber(user?.total_fund_complete)} Dự Án
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card full-height">
              <div className="card-header">
                <div className="card-title">Thông Báo Hệ Thống</div>
              </div>
              <div className="card-body">
                <Notice />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Home;
