import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { useQuery } from "@tanstack/react-query";
import { UserFundApi } from "states/api/userFund";
import { formatNumber } from "_common/utils/formatValue";
import moment from "moment";
import { fundUserStatusText } from "_common/constants/common";
import { Link } from "react-router-dom";

const MyInvestment = observer(() => {
  const {
    authStore: { general },
  } = useStores();

  const [params, setParams] = useState({});

  const { data: dataState, refetch } = useQuery(
    ["user-fund", "list", params],
    () =>
      UserFundApi.getList({
        params: {
          page: 1,
          limit: 30,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="page-inner">
      <div className="page-header">
        <h4 className="page-title">Lịch Sử Đầu Tư</h4>
        <ul className="breadcrumbs">
          <li className="nav-home">
            <Link to="/">
              <i className="fa fa-home" />
            </Link>
          </li>
          <li className="separator">
            <i className="fas fa-chevron-right" />
          </li>
          <li className="nav-item">
            <Link to="/dau-tu">Dự Án Đầu Tư</Link>
          </li>
          <li className="separator">
            <i className="fas fa-chevron-right" />
          </li>
          <li className="nav-item">
            <Link to="/my-investment">Lịch Sử Đầu Tư</Link>
          </li>
        </ul>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Lịch Sử Đầu Tư</div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover table-striped">
                  <thead>
                    <tr>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        Mã Giao Dịch
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        Gói Đầu Tư
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        Vốn Đầu Tư
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        Lãi Suất
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        Lợi Nhuận Ước Tính
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        Thời Hạn
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        Thời Gian Đầu Tư
                      </th>
                      <th
                        className="text-center"
                        style={{
                          color: "rgba(169, 175, 187, 0.82)",
                          backgroundColor: "unset",
                        }}
                      >
                        Trạng Thái
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataState?.data?.map((item) => (
                      <tr key={item?.id}>
                        <td>{item?.id}</td>
                        <td>{item?.fund?.name}</td>
                        <td>{formatNumber(item?.amount)}</td>
                        <td>{formatNumber(item?.fund?.profit)}</td>
                        <td>{formatNumber(item?.fund?.profit_est)}</td>
                        <td>{formatNumber(item?.fund?.time)}</td>
                        <td>
                          {moment(item?.created_at).format("DD/MM/YYYYY HH:mm")}
                        </td>
                        <td>{fundUserStatusText[item?.status]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MyInvestment;
