import { get } from "lodash";
import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

export class AuthStore {
  token = "";

  user = null;

  device = "";

  general = {};

  bank = {};

  logo = "";

  banners = [];

  vips = [];

  logo_white = "";

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "AuthStore",
      properties: ["token", "user", "device"],
      storage: window.localStorage,
    });
  }

  get authenticated() {
    // TODO: This logic can be changed on demand
    return !!this.token;
  }

  clear = () => {
    this.token = "";
    this.user = null;
  };

  setInitData = (data) => {
    this.device = data?.device_code;
    this.banners = data?.banners || [];
    this.vips = data?.vips || [];
    const general = data?.general || [];

    if (Array.isArray(general)) {
      const logoData = general?.find((obj) => obj?.name === "logo");
      if (logoData) {
        this.logo = logoData?.image_url;
      }

      const logoWhiteData = general?.find((obj) => obj?.name === "logo_white");
      if (logoWhiteData) {
        this.logo_white = logoWhiteData?.image_url;
      }
    }

    const newsValue = {};

    general.forEach((item, index) => {
      newsValue[item.name] = item.value;
      if (item.value === "0") {
        newsValue[item.name] = false;
      }
    });

    this.general = newsValue;
  };

  updateToken = (token) => {
    this.token = token || this.token;
  };

  updateUser = (data) => {
    this.user = data.user;
    this.bank = data?.user?.banks[0];
  };
}
