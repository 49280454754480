import React from "react";
import { Outlet } from "react-router-dom";
import { useStores } from "_common/hooks";
import HeaderTop from "../Header/HeaderTop";
import Sidebar from "../Sidebar/Sidebar";
import MainPanel from "../MainPanel/MainPanel";

export default function MobileLayout() {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="wrapper">
      <HeaderTop />
      <Sidebar />
      <MainPanel />
    </div>
  );
}
