import React from "react";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faHomeAlt, faSearch } from "@fortawesome/free-solid-svg-icons";

import faviconSrc from "assets/img/favicon.png";
import avatarSrc from "assets/img/profile.jpg";

function Sidebar({ isBack = true, title }) {
  const {
    authStore: { authenticated },
  } = useStores();
  const location = useLocation();
  const pathName = location?.pathname;

  const handleSidebarHover = () => {
    const [wrapper] = document.getElementsByClassName("wrapper");

    if (wrapper.classList.contains("sidebar_minimize"))
      wrapper.classList.add("sidebar_minimize_hover");
  };

  const handleSidebarOut = () => {
    const [wrapper] = document.getElementsByClassName("wrapper");

    if (wrapper.classList.contains("sidebar_minimize"))
      wrapper.classList.remove("sidebar_minimize_hover");
  };

  return (
    <div
      className="sidebar sidebar-style-2"
      data-background-color="dark2"
      onFocus={handleSidebarHover}
      onMouseOver={handleSidebarHover}
      onBlur={handleSidebarOut}
      onMouseOut={handleSidebarOut}
    >
      <div
        className="scroll-wrapper sidebar-wrapper scrollbar scrollbar-inner"
        style={{ position: "relative" }}
      >
        <div
          className="sidebar-wrapper scrollbar scrollbar-inner scroll-content"
          style={{
            height: "651.6px",
            marginBottom: "0px",
            marginRight: "0px",
            maxHeight: "none",
          }}
        >
          {authenticated && (
            <div className="sidebar-content">
              <ul className="nav nav-primary">
                <li
                  className={classNames("nav-item", {
                    active: pathName === "/" || pathName === "/home",
                  })}
                >
                  <Link to="/home">
                    <i className="fas fa-home" />
                    <p>Tổng Quan Tài Khoản</p>
                  </Link>
                </li>
                <li
                  className={classNames("nav-item", {
                    active: pathName === "/profile",
                  })}
                >
                  <Link to="/profile">
                    <i className="icon-user" />
                    <p>Thông Tin Cá Nhân</p>
                  </Link>
                </li>

                <li
                  className={classNames("nav-item", {
                    active: pathName === "/gioi-thieu",
                  })}
                >
                  <Link to="/gioi-thieu">
                    <i className="icon-people" />
                    <p>Giới Thiệu Đầu Tư</p>
                  </Link>
                </li>
                <li
                  className={classNames("nav-item", {
                    active: pathName === "/nhom-cua-toi",
                  })}
                >
                  <Link to="/nhom-cua-toi">
                    <i className="icon-people" />
                    <p>Đội của tôi</p>
                  </Link>
                </li>
                <li
                  className={classNames("nav-item", {
                    active: pathName === "/nap-tien",
                  })}
                >
                  <Link to="/nap-tien">
                    <i className="icon-wallet" />
                    <p>Nạp Tiền Tài Khoản</p>
                  </Link>
                </li>
                <li
                  className={classNames("nav-item", {
                    active: pathName === "/rut-tien",
                  })}
                >
                  <Link to="/rut-tien">
                    <i className="fas fa-money-check-alt" />
                    <p>Rút Tiền Tài Khoản</p>
                  </Link>
                </li>

                <li
                  className={classNames("nav-item", {
                    active: pathName === "/dau-tu",
                  })}
                >
                  <Link to="/dau-tu">
                    <i className="fas fa-piggy-bank" />
                    <p>Dự Án Đầu Tư</p>
                  </Link>
                </li>
                <li
                  className={classNames("nav-item", {
                    active: pathName === "/my-investment",
                  })}
                >
                  <Link to="/my-investment">
                    <i className="icon-graph" />
                    <p>Lịch Sử Đầu Tư</p>
                  </Link>
                </li>
                <li
                  className={classNames("nav-item", {
                    active: pathName === "/cong-ty",
                  })}
                >
                  <Link to="/cong-ty">
                    <i className="fa fa-info" />
                    <p>Giới Thiệu</p>
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="scroll-element scroll-x">
          <div className="scroll-element_outer">
            <div className="scroll-element_size" />
            <div className="scroll-element_track" />
            <div
              className="scroll-bar ui-draggable ui-draggable-handle"
              style={{ width: "100px" }}
            />
          </div>
        </div>
        <div className="scroll-element scroll-y">
          <div className="scroll-element_outer">
            <div className="scroll-element_size" />
            <div className="scroll-element_track" />
            <div
              className="scroll-bar ui-draggable ui-draggable-handle"
              style={{ height: "99px", top: "0px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
